import { useContext } from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { AppContext, set_User } from "../context";
import Home from "./Home";
import { AuthProviderFunc } from "../functions";

function Dashboard() {
  const { state: { _User, appConfig }, dispatch, } = useContext(AppContext);
  const authProvider = AuthProviderFunc({ appConfig });

  const setUserInfo = (acInfo) => {
    let obj = {
      'name': acInfo?.account?.name || "",
      'email': acInfo?.account?.userName || "", // currently getting email in the userName key
      'token': acInfo.jwtIdToken,
      'lastUpdated': new Date().toUTCString()
    };
    // console.log("_User.token?.length === 0", _User.token?.length === 0);
    _User.token?.length === 0 && set_User(dispatch, obj);
    let localUser = localStorage.getItem("@User");

    localUser = JSON.parse(localUser);
    if ((localUser === null) || (localUser?.token !== acInfo.jwtIdToken)) {
      localStorage.setItem("@User", JSON.stringify(obj));
    }
  };

  const checkIsUnAuthenticated = () => {
    let localUser = localStorage.getItem("@User");
    localUser !== null && localStorage.removeItem("@User");
  };

  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            setUserInfo(accountInfo);
            return <Home />;
          case AuthenticationState.Unauthenticated:
            checkIsUnAuthenticated();
            return (
              <div>
                {error && (
                  <p>
                    <span>An error occured during authentication, please try again!</span>
                  </p>
                )}
                <p>
                  <span>Hey stranger, you look new!</span>
                </p>
                <button onClick={login}>Login</button>
              </div>
            );
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>;
          default:
            return <p>Loading...</p>;
        }
      }}
    </AzureAD>
  );
};

export default Dashboard;