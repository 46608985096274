/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { sidebarData } from "../../../utils";
import { useIntlMsg } from "../../../i18n";

export function AsideMenuMain() {
  const [isOpen, setIsOpenState] = useState("");

  const setIsOpen = (title: string) => {
    title === isOpen ? setIsOpenState("") : setIsOpenState(title);
    // console.log("title__", title);
  };
  const { pathname } = useLocation();
  let { t } = useIntlMsg();

  useEffect(() => {
    if (pathname.includes("order")) setIsOpen("Sales");
    else { setIsOpen(""); }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      {sidebarData.map(({ menuTitle, menuLocal, to, icon, activeIc, items, linkUrl }, index) => {
        return items.length > 0 ? to === null ? (
          <AsideMenuItemWithSub
            key={index}
            to={to}
            title={t(menuLocal)}
            icon={icon}
            isOpen={isOpen === menuTitle}
            setIsOpen={setIsOpen}
            activeIc={activeIc}
          >
            {items.map((item, idx) => (
              <AsideMenuItem
                key={idx}
                to={item.to}
                title={t(item.menuLocal)}
                icon={item.icon}
                activeIc={item.activeIc}
                url={item.linkUrl}
              />
            ))}
          </AsideMenuItemWithSub>
        ) : (
          <AsideMenuItem
            key={index}
            to={to}
            title={t(menuLocal)}
            icon={icon}
            activeIc={activeIc}
            IconAlign
            url={linkUrl}
          />
        ) : (
          <div className="p-3" key={index}>
            <AsideMenuItem
              to={to}
              title={t(menuLocal)}
              icon={icon}
              activeIc={activeIc}
              IconAlign
              url={linkUrl}
            />
          </div>
        )
      })}
    </>
  )
}


//  return (
//     <Fragment>
//       <div className="p-3">
//         <AsideMenuItem
//           to="/home"
//           title="Home"
//           icon="/images/Home-icon.svg"
//           activeIc="/images/Home-Active-ic.svg"
//           IconAlign
//           url={null}
//         />
//       </div>
//       {/* <AsideMenuItemWithSub
//         to="/general"
//         title="General"
//         icon="/images/General-icon.svg"
//         isOpen={isOpen === "General"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/General-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/general/today"
//           title="Today"
//           icon="/images/Today-icon.svg"
//           activeIc="/images/Today-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/general/activities"
//           title="Activities"
//           icon="/images/Activities-icon.svg"
//           activeIc="/images/Activities-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/general/contacts"
//           title="Contacts"
//           icon="/images/Contacts-icon.svg"
//           activeIc="/images/Contacts-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/error"
//         title="Communication"
//         icon="/images/Communication-icon.svg"
//         isOpen={isOpen === "Communication"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Communication-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/communication/bulkemail"
//           title="Bulk Email"
//           icon="/images/BulkEmail-icon.svg"
//           activeIc="/images/BulkEmail-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/media"
//         title="Media"
//         icon="/images/Media-icon.svg"
//         isOpen={isOpen === "Media"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Media-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/media/news"
//           title="News"
//           icon="/images/News-icon.svg"
//           activeIc="/images/News-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/media/events"
//           title="Events"
//           icon="/images/Events-icon.svg"
//           activeIc="/images/Events-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/media/campaigns"
//           title="Campaigns"
//           icon="/images/Campaigns-icon.svg"
//           activeIc="/images/Campaigns-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/products"
//         title="Products"
//         icon="/images/Products-icon.svg"
//         isOpen={isOpen === "Products"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Products-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/products/daily"
//           title="Daily"
//           icon="/images/Daily-icon.svg"
//           activeIc="/images/Daily-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/products/summary"
//           title="Summary"
//           icon="/images/Summary-icon.svg"
//           activeIc="/images/Summary-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/products/stocktransfers"
//           title="Stock Transfers"
//           icon="/images/StockTransfers-icon.svg"
//           activeIc="/images/StockTransfers-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/products/warranties"
//           title="Warranties"
//           icon="/images/Warrantien-icon.svg"
//           activeIc="/images/Warranties-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/products/translations"
//           title="Translations"
//           icon="/images/Translations-icon.svg"
//           activeIc="/images/Translations-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       <AsideMenuItemWithSub
//         to="/sales"
//         title="Sales"
//         icon="/images/Sales-icon.svg"
//         activeIc="/images/Sales-Active-ic.svg"
//         isOpen={isOpen === "Sales"}
//         setIsOpen={(t) => {
//           setIsOpen(t);
//         }}
//       >
//         {/* <AsideMenuItem
//           to="/sales/leads"
//           title="Leads"
//           icon="/images/Leads-icon.svg"
//           activeIc="/images/Leads-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/sales/prospects"
//           title="Prospects"
//           icon="/images/Prospects-icon.svg"
//           activeIc="/images/Prospects-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/sales/customers"
//           title="Customers"
//           icon="/images/Customers-icon.svg"
//           activeIc="/images/Customers-Active-ic.svg"
//         /> */}
//         <AsideMenuItem
//           to="/sales/order"
//           title="Order"
//           icon="/images/Orders-icon.svg"
//           activeIc="/images/Orders-Active-ic.svg"
//           url={null}
//         />
//       </AsideMenuItemWithSub>

//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/quotes"
//         title="Quotes"
//         icon="/images/Quotes-icon.svg"
//         isOpen={isOpen === "Quotes"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Quotes-Active-ic.svg"
//       /> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/finance"
//         title="Finance"
//         icon="/images/Finance-icon.svg"
//         isOpen={isOpen === "Finance"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Finance-Active-ic.svg"
//       /> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/orderprocessing"
//         title="Order Processing"
//         icon="/images/OrderProcessing-icon.svg"
//         isOpen={isOpen === "Order Processing"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/OrderProcessing-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/orderprocessing/Customers"
//           title="Customers"
//           icon="/images/Customers-icon.svg"
//           activeIc="/images/Customers-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/order"
//           title="Orders"
//           icon="/images/Orders-icon.svg"
//           activeIc="/images/Orders-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/orderprocessing/shipments"
//           title="Shipments"
//           icon="/images/Shipments-icon.svg"
//           activeIc="/images/Shipments-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/technical"
//         title="Technical"
//         icon="/images/Technical-icon.svg"
//         isOpen={isOpen === "Technical"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Technical-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/technical/general"
//           title="General"
//           icon="/images/General-icon.svg"
//           activeIc="/images/General-Active-ic.svg"
//         />
//         <AsideMenuItem
//           to="/technical/activities"
//           title="Activities"
//           icon="/images/Activities-icon.svg"
//           activeIc="/images/Activities-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/returns"
//         title="Returns"
//         icon="/images/Returns-icon.svg"
//         isOpen={isOpen === "Returns"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Returns-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/returns/summary"
//           title="Summary"
//           icon="/images/Summary-icon.svg"
//           activeIc="/images/Summary-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//       {/* <AsideMenuItemWithSub
//         to="/logs"
//         title="Logs"
//         icon="/images/Logs-icon.svg"
//         isOpen={isOpen === "Logs"}
//         setIsOpen={setIsOpen}
//         activeIc="/images/Logs-Active-ic.svg"
//       >
//         <AsideMenuItem
//           to="/logs/qualitylogs"
//           title="Quality Logs"
//           icon="/images/QualityLogs-icon.svg"
//           activeIc="/images/QualityLogs-Active-ic.svg"
//         />
//       </AsideMenuItemWithSub> */}
//       {/* ============================================================================================  */}
//     </Fragment>
//   );