import { RefObject, useContext } from "react";
import { GETreq as GETrequest, POSTreq as POSTrequest, DELETEreq as DELETErequest, PATCHreq as PATCHrequest } from "../services";
import { AlertIcType } from "../types";
import { AppContext, set_Token } from "../context";
import { AuthProviderFunc, getRefreshToken, isTokenAboutToExpire } from "../functions";

function useAPI() {
    const { state: { _User: { token }, appConfig }, dispatch } = useContext(AppContext);
    const authProvider = AuthProviderFunc({ appConfig });
    const { state: { appConfig: { SOPAPLURL } } } = useContext(AppContext);
    const isAboutToExpire = isTokenAboutToExpire(authProvider, 0);

    async function newTok() {
        if (isAboutToExpire) {
            const tok = await getRefreshToken(authProvider); console.log("aboutTo expire", true);
            set_Token(dispatch, tok); return (tok);
        } else { console.log("aboutTo expire", false); return token; };
    };
    /*    
       const isExpired = isTokenAboutToExpire(authProvider, 0);
       async function oneHrTok() {
           if (isExpired) {
               const tok = await getRefreshToken(authProvider);
               set_Token(dispatch, tok); return (tok);
           } else { return token };
       }
    */
    const GETreq = async (path: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }, myURL?: string) => {
        return GETrequest(`${SOPAPLURL}/${path}`, await newTok(), toText, opts, myURL);
    };

    const POSTreq = async (path: string, body?: any, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType>, xHeader?: boolean }) => {
        return POSTrequest(`${SOPAPLURL}/${path}`, body, await newTok(), toText, opts);
    };
    const DELETEreq = async (path: string, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
        return DELETErequest(`${SOPAPLURL}/${path}`, await newTok(), toText, opts);
    };

    const PATCHreq = async (path: string, body?: any, toText?: boolean, opts?: { /* signal: AbortSignal; */ ref?: RefObject<AlertIcType> }) => {
        return PATCHrequest(`${SOPAPLURL}/${path}`, body, await newTok(), toText, opts);
    };


    return { GETreq, POSTreq, DELETEreq, PATCHreq };
};

export default useAPI;