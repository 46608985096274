import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { useIntlMsg } from "../../i18n";

interface Props {
  isStock: boolean;
}
function OrderDate({ isStock }: Props) {
  const { state: { _User: { lastUpdated } } } = useContext(AppContext);
  let { t } = useIntlMsg();

  const [dateObj, setDateObj] = useState({ date: "-- ---, ----", time: "--:--:--", });
  useEffect(() => {
    const localDate = lastUpdated || localStorage.getItem("@lastUpdated") || new Date().toUTCString();
    if (!localDate) return;
    var newDate = new Date(localDate);
    if (newDate.toString() === "Invalid Date") { newDate = new Date(); localStorage.removeItem("@lastUpdated"); }
    setDateObj({ date: dayjs(newDate).format("DD MMM, YYYY"), time: dayjs(newDate).format("HH:mm:ss") });
  }, [lastUpdated]);

  return (
    <div className="order-card">
      <span>{isStock === true ? t("SALES.ORDER.LAST_UPDATE") : "Last Searched"}</span>
      <div className="order-card-flex">
        <div className="order-card__flex">
          <img src="/images/Calendar-icon.svg" className="mr_10px" alt="icon" />
          <span>{dateObj.date}</span>
        </div>
        <div className="order-card__flex-row">
          <img src="/images/Time-icon.svg" className="mr_10px" alt="icon" />
          <span>{dateObj.time}</span>
        </div>
      </div>
    </div>
  );
}

export default OrderDate;
