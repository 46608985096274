import { IAction, IAppState } from "../types";
import { initialState } from "../utils";
import { ActionType } from "./action";

const appReducer = (state: IAppState, { type, User, isLoading, lastUpdated, aConf, intlObj, token }: IAction): typeof initialState => {

    switch (type) {
        case ActionType.SET_USER: {
            return {
                ...state,
                _User: User === undefined ? state._User : User
            }
        }
        case ActionType.RESET: {
            return {
                ...state,
                _User: { ...initialState._User }
            }
        }
        case ActionType.SET_LOADING: {
            return {
                ...state,
                isLoading: isLoading === undefined ? state.isLoading : isLoading
            }
        }
        case ActionType.SET_TOKEN: {
            return {
                ...state,
                _User: { ...state._User, token: token === undefined ? state._User.token : token }
            }
        }
        case ActionType.SET_LAST_UPDATED: {
            return {
                ...state,
                _User: {
                    ...state._User,
                    lastUpdated: lastUpdated === undefined ? state._User.lastUpdated : lastUpdated
                }
            }
        }
        case ActionType.SET_APPCONFIG: {
            return {
                ...state,
                appConfig: aConf === undefined ? state.appConfig : aConf
            }
        }
        case ActionType.SET_INTL_OBJ: {
            return {
                ...state,
                intlObj: intlObj === undefined ? state.intlObj : intlObj,
                loadingIntl: false
            }
        }
        default: return state;
    }
};

export default appReducer;