const ErrorHandleHook = (code: number, eMsg: string, customMsg = '') => {
    var obj = { sCode: 200, eMessage: '', msg: '' };
    var err500 = "SOme thing went to wrong";
    // var err500 = t("somethingWentWrong");
    if (code === 404) {
        obj = { sCode: 404, eMessage: eMsg, msg: customMsg }
        // } else if (code === 401) {
        //     obj = { sCode: 401, eMessage: 'err401', msg: customMsg }
    } else if (code >= 500) {
        obj = { sCode: 500, eMessage: eMsg === err500 ? "Internal Server Error" : eMsg, msg: "" }
    } else if (code === 403) {
        obj = { sCode: 403, eMessage: 'perDenied', msg: "" }
    } else if (code === 400) {
        obj = { sCode: 400, eMessage: eMsg, msg: customMsg }
    } else {
        obj = { sCode: code, eMessage: eMsg, msg: customMsg }
    }
    return obj;
}

export default ErrorHandleHook;