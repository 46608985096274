import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../helpers";

type Props = {
  to?: string | null;
  title: string;
  icon?: string;
  activeIc?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  IconAlign?: boolean;
  url: string | null;
};

const AsideMenuItem: FC<Props & WithChildren> = ({ children, to, title, icon, activeIc, fontIcon, hasBullet, IconAlign, url }) => {
  const { pathname } = useLocation();
  const isActive = to ? checkIsActive(pathname, to) : false;
  const ic = isActive ? activeIc || icon || "" : icon || "";

  return (
    <div className="asidemenuitem" data-kt-menu-trigger="click">
      <div className="menu-item">
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={to ||/* ? to : url ? { pathname: url } :  */"#"}
          // target={url ? "_blank" : undefined}
          // rel={url ? "noreferrer" : undefined}
          onClick={(e) => {
            if (url) {
              e.preventDefault();
              window.open(url, "_blank");
            }
          }}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && (
            <span className={IconAlign ? "menu-icon_" : "menu-icon"}>
              <KTSVG path={ic} className="svg-icon-2" />
            </span>
          )}
          {fontIcon && <i className={clsx("bi fs-3", fontIcon)}></i>}
          <span className="menu-title">{title}</span>
        </Link>
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItem };
