import { LoginType, MsalAuthProvider } from "react-aad-msal";
import { IAppState } from "../types";

function useAuthProvider({ appConfig: {
    'MSAL_AZUREAD_CLIENT_ID': clientId,
    'MSAL_AZURE_SIGNIN_AUTHORITY': authority,
    'LOGIN_LOGOUT_REDIRECT_URL': redirectUri
} }: IAppState) {
    /* const { appConfig: {
        'MSAL_AZUREAD_CLIENT_ID': clientId,
        'MSAL_AZURE_SIGNIN_AUTHORITY': authority,
        'LOGIN_LOGOUT_REDIRECT_URL': redirectUri
    } } = useFeatureFlag(); */

    const config = {
        auth: { clientId, authority, redirectUri },
        cache: {
            CacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };

    // Authentication Parameters
    const authenticationParameters = { scopes: ['user.read'] }

    // Options
    const options = {
        loginType: LoginType.Redirect,
        tokenRefreshUri: window.location.origin + '/auth.html'
    };

    return new MsalAuthProvider(config, authenticationParameters, options);
};

export default useAuthProvider;