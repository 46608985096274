import { FC, ReactNode, useReducer } from "react";
import { initialState } from "../utils";
import { IAppState } from "../types";
import appReducer from "./reducer";
import { AppContext } from "./";

const AppContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState as IAppState);
    const val = { state, dispatch };

    return (
        <AppContext.Provider value={val}>{children}</AppContext.Provider>
    )
};

export { AppContextProvider };