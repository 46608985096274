function Home({ logout }: { logout?: () => void }) {
  return (
    <main>
      <h1>Home !</h1>
      {logout && <button onClick={logout}>Logout</button>}
    </main>
  );
}

export default Home;
