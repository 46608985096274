import { Fragment } from "react";
import { useLocation } from "react-router";
// import { Link } from "react-router-dom";
// import { Languages } from "../../../partials";

function HeaderToolbar() {
  const { pathname } = useLocation();
  // const pathslash = pathname.replace(/^\//, "");
  const pathArr =
    pathname === "/" ? [] : pathname.replace(/^\//, "").split("/");
  return (
    <>
      <div className="toolbar d-flex align-items-stretch">
        <div className="header-title-mr">
          {pathArr.length > 0 ? (
            <>
              <h1>{pathArr[pathArr.length - 1]}</h1>
              {/* <Link to="/">
                <span className="header-title-text">Order</span>
              </Link> */}
              {pathArr.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <span>
                      <span
                        className={
                          pathArr[pathArr.length - 1] === item
                            ? "header-title__text"
                            : "header-title-text"
                        }
                      >
                        {item}
                        {pathArr?.length - 1 !== index && <img
                          src="/images/dash-icon.svg"
                          alt=""
                          className="dash-icon"
                        />}
                      </span>
                    </span>
                  </Fragment>
                );
              })}
            </>
          ) : (
            <h1 className="display-6">Home</h1>
          )}
        </div>
        {/* <Languages /> */}
      </div>
    </>
  );
}

export default HeaderToolbar;

// {`${classes.headerContainer.join(
//   " "
// )}
