import React from "react";
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
} from "react-select";
import { getOptions } from "../functions";

export interface SelectionType {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

interface Props {
  placeholder: string;
  dataArr: SelectionType[] | any[];
  selectedObj: SelectionType | object | any;
  onSelect: (e: SelectionType | object | any) => void;
  isLoading?: boolean;
  label: string,
  value: string,
}

const SearchSelection = ({
  placeholder,
  dataArr,
  selectedObj,
  isLoading,
  onSelect,
  label,
  value,
}: Props) => {
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        {!isLoading ?
          <img src="/images/Arrow-icon.svg" alt="icon" /> :
          <div className="dropdown-blue-loader"/>
        }
      </components.DropdownIndicator>
    );
  };
  const handleTypeSelect = (e: any) => onSelect(e ?? null);

  return (
    <Select
      menuPortalTarget={document.body}
      isClearable
      isSearchable
      name={placeholder?.trim()?.toLocaleLowerCase() ?? ""}
      menuPlacement="auto"
      menuPosition="fixed"
      isLoading={isLoading}
      options={getOptions(label, value, dataArr)}
      // options={dataArr}
      classNamePrefix="select"
      placeholder={placeholder}
      className="search-selection"
      onChange={handleTypeSelect}
      components={{ DropdownIndicator }}
      value={selectedObj}
      tabSelectsValue
      styles={{
        indicatorSeparator: () => ({ display: "none" }),
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: "none",
          backgroundColor: "transparent",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 1300 }),
        container: (baseStyle) => ({
          ...baseStyle,
          minWidth: "100%",
          paddingRight: "1%",
        }),
        placeholder: (baseStyle) => ({
          ...baseStyle,
          color: "#B5B5C3",
          fontSize: 14,
          fontWeight: "500",
        }),
        option: (baseStyle, { isFocused, isSelected }) => ({
          ...baseStyle,
          backgroundColor: isFocused
            ? "#F5F5F5"
            : isSelected
              ? "#f4f7fb"
              : "#FFFFFF",
          color: isSelected ? "#3699FF" : "#555555",
          fontSize: 14,
          fontWeight: isFocused || isSelected ? "500" : "400",
        }),
        input: (baseStyle) => ({
          ...baseStyle,
          fontSize: 14,
          fontWeight: "500",
        }),
        singleValue: (baseStyle) => ({
          ...baseStyle,
          fontSize: 14,
          fontWeight: "500",
        }),
      }}
    />
    
  );
};

export default SearchSelection;
