import { useContext, useEffect, useState } from "react";
import { AuthProviderFunc, getAccount, getAuthToken, getLocalUser, getRefreshToken } from "../functions";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import { AuthenticationState } from "react-aad-msal";
import { AppContext, set_User } from "../context";
import { Order, Dashboard, Home } from "../pages";
import { set_Loading } from "../context/action";
import { PageLoader } from "../components";
import { useIntlMsg } from "../i18n";

function PrivateRoutes() {
  const { state: { isLoading, appConfig }, dispatch } = useContext(AppContext);
  const authProvider = AuthProviderFunc({ appConfig });
  const [isAuth, setIsAuth] = useState(false);
  let { name, email } = getLocalUser();
  let { t } = useIntlMsg();

  useEffect(() => {
    const updateToken = async (isNew = false) => {
      const tok = isNew ? await getRefreshToken(authProvider) : await getAuthToken(authProvider);
      let acInfo = getAccount(authProvider);
      // console.log("acInfo", tok);
      set_User(dispatch, {
        'email': email || acInfo?.userName,
        'name': name || acInfo?.name,
        'token': tok, 'lastUpdated': null
      });
      setIsAuth(true);
      set_Loading(dispatch, false);
    };
    authProvider.authenticationState === AuthenticationState.Authenticated ? updateToken() : authProvider.authenticationState === AuthenticationState.Unauthenticated ? updateToken(true) : set_Loading(dispatch, false);
    // }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, email, name]);

  if (isLoading) return <PageLoader isLoading />;


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {isAuth ? (
          <>
            <Route path="/" element={<Navigate to="/sales/order" />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/home" element={<Dashboard />} /> */}
            <Route path="/sales/order" element={<Order />} />
            {/* <Route path="/orders" element={<Dashboard />} /> */}
            <Route path="*" element={
              <main className="error-404">
                <h1 className="user_sel_h1 user-sel-none">{t("ERR.404")}</h1>
                <p className="user_sel_p user-sel-none">{t("ERR.404.DESC")}</p>
                <Link className="user_sel_link user-sel-none" to="/">{t("ERR.BACK_TO_HOME")}</Link>
              </main>}
            />
          </>
        ) : (
          <Route path="*" element={<Dashboard />} />
        )}
      </Route>
    </Routes>
  );
}

export { PrivateRoutes };