import { useEffect, useState, useContext, useRef } from "react";
import { BrandData, Customer, State, Status, Type } from "../../fake_data";
import PopupPartNo from "../../components/popup/Popup-partNo";
import { /* Calendar,  */SearchSelection } from "../../components";
import { ILayout } from "../../layout/core";
import { AppContext, /* resetUser */ } from "../../context";
import { dDownType } from "../../types";
import { AuthProviderFunc, getAccount, /* onLogout */ } from "../../functions";
import { useIntlMsg } from "../../i18n";
import { useAPI } from "../../hooks";

interface Props {
    config: ILayout;
    updateData: (fieldsToUpdate: Partial<ILayout>) => void;
    isStock: boolean;
    handleSearchButton: () => void;
    selectedOrdProc: dDownType;
    setSelectedOrdProc: (e: object | any) => void;
    selectedStockLocation: dDownType;
    setSelectedStockLocation: (e: object | any) => void;
    selectedStockLocGroup: dDownType;
    setSelectedStockLocGroup: (e: object | any) => void;
    isReseted: boolean;
    setIsReseted: (v: boolean) => void;
    loading: boolean;
    onResetFunc?: () => void;
}

function SearchCriteria({ config, updateData, isStock, handleSearchButton, loading, onResetFunc = () => { }, selectedOrdProc, setSelectedOrdProc, selectedStockLocation, setSelectedStockLocation, selectedStockLocGroup, setSelectedStockLocGroup, isReseted, setIsReseted }: Props) {
    const { state: { appConfig }, /* dispatch */ } = useContext(AppContext);
    const authProvider = AuthProviderFunc({ appConfig });
    const { GETreq } = useAPI();
    const { header } = config;
    let { t } = useIntlMsg();

    // const [dateFrom, setDateFrom] = useState<Date | null | string>(null); // selected date from
    // const [dateTo, setDateTo] = useState<Date | null | string>(null); // selected date to
    const [brand, setBrand] = useState(null); // selected brand
    const [status, setStatus] = useState(null); // selected status
    const [state, setState] = useState(null); // selected state
    const [customer, setCustomer] = useState(null); // selected customer
    const [type, setType] = useState(null); // selected type
    const [shipment, setShipment] = useState(null);
    const [selArr, setSelArr] = useState<any[]>([]);
    const [checkedArr, setCheckedArr] = useState<any[]>([]);

    const [ordProcesserData, setOrdProcesserData] = useState<any[]>([]); // order processor data
    const [loadingProc, setLoadingProc] = useState<boolean>(false); // loading order processor data

    const [stockLocationData, setStockLocationData] = useState<any[]>([]); // stock location data
    const [loadingStockGroup, setLoadingStockGroup] = useState<boolean>(false); // loading stock location group data

    const [stockLocationGroupData, setStockLocationGroupData] = useState<any[]>([]); // stock location group data
    const [loadingStockLocation, setLoadingStockLocation] = useState<boolean>(false); // loading stock location data
    const [finalArr, setFinalArr] = useState<any[]>([]); // final stock location data

    const preventMultiple = useRef(false);
    const unAuthFun = () => {
        preventMultiple.current = true
        if (/* !preventMultiple.current &&  */window.confirm(t("ERR.SESSION_EXPIRE")) === true) {
            window?.location?.reload();
            /* localStorage.removeItem("@User");
            resetUser(dispatch); onLogout(authProvider); */
        };
    };


    // const [selectedOrdProc, setSelectedOrdProc] = useState(new Map());

    const onSelect = (itm: any, isRemove: boolean = false) => {
        // console.log("itm", isRemove, itm);
        // chek if item is already selected then remove it otherwise add it, if got same item then remove it
        // check if isRemove is true then remove it
        let cloneArr = [...checkedArr];
        let cloneCheckedArr = [...checkedArr];
        const index = cloneArr.findIndex((item) => item.PartNo === itm.PartNo);
        const idx = cloneCheckedArr.findIndex((item) => item.PartNo === itm.PartNo);
        if (isRemove) {
            // remove item from checkedArr and selArr
            cloneCheckedArr.splice(idx, 1); cloneArr.splice(index, 1);
            setSelArr(cloneCheckedArr);
        } else { index > -1 ? cloneArr.splice(index, 1) : cloneArr.push(itm); }
        setCheckedArr(cloneArr);
    };

    const onApply = () => setSelArr(checkedArr);

    async function getOrderProcessor() {
        setLoadingProc(true);
        await GETreq("MasterData/GetOrderProcessorList").then(({ code, res }) => {
            if (code === 200) {
                setOrdProcesserData(res);
                let accountInfo = getAccount(authProvider);
                let obj = res?.find((e: any) => e.emailAddress === accountInfo?.userName) || undefined;
                // let obj = res?.find((e: any) => e.emailAddress === email) || undefined;
                obj && setSelectedOrdProc({ value: obj?.orderProcessorId, label: obj?.name });
                setLoadingProc(false);
            } else if (code === 401) { unAuthFun(); }
        }).catch(e => console.error("OrdProcErr:", e)).finally(() => setLoadingProc(false));
    };


    async function getStockLocation() {
        setLoadingStockGroup(true);
        setLoadingStockLocation(true);
        await GETreq("MasterData/GetStockLocationsMasterData").then(({ code, res }) => {
            if (code === 200) {
                setStockLocationData(res?.stockLocations);
                setFinalArr(res?.stockLocations);
                setStockLocationGroupData(res?.stockLocationGroups);
                setLoadingStockGroup(false);
            } else if (code === 401) { unAuthFun(); }
        }).catch(e => console.error("stockLocErr:", e)).finally(() => { setLoadingStockGroup(false); setLoadingStockLocation(false); });
    };

    // eslint-disable-next-line
    useEffect(() => { getOrderProcessor(); getStockLocation(); }, [])

    const handleReset = () => {
        setSelectedStockLocGroup(null);
        setSelectedStockLocation(null);
        // setSelectedOrdProc("");
        let listStock: any[] = [];
        finalArr?.forEach((item) => { listStock?.push(item); })
        setStockLocationData(listStock);
        onResetFunc();
    }

    const handleStocklocationGroup = (id: any) => {
        setSelectedStockLocation(null);
        setLoadingStockLocation(true);
        setSelectedStockLocGroup(id);
        let tempArr: any[] = [];
        if (id === null) { finalArr?.forEach((item) => { tempArr?.push(item); }) }
        else { finalArr?.forEach((item) => { if (item?.stockLocationGroupId === id?.value) { tempArr?.push(item); } }) }
        setStockLocationData(tempArr);
        setLoadingStockLocation(false);
    }


    return (
        <section className="search-criteria">
            <div className="search-toggles">
                <div className="search-criteria-header-title">{t("SEARCH_CRITERIA")}</div>
                <label className="form-check form-check-custom form-check-solid form-switch mb-3 ">
                    <span className="form-check-label text-muted">{t("SHOW_ORDER_PRIORITY")}</span>
                    <input
                        className="form-check-input ms-2"
                        disabled
                        type="checkbox"
                        checked={header.fixed.tabletAndMobile}
                        onChange={() => updateData({ header: { ...header, fixed: { ...header.fixed, tabletAndMobile: !header.fixed.tabletAndMobile } } })}
                    />
                </label>
            </div>
            <div className="search-criteria-pb">
                {!isStock && (
                    <>
                        <div className="search-criteria-flex">
                            <div className="search-criteria-pb-col">
                                <span>{t('BRANDS')}</span>
                                <div className="search-criteria-pb1-search">
                                    <SearchSelection placeholder={t("SELECT_BRANDS")} dataArr={BrandData} selectedObj={brand} onSelect={setBrand} label="label" value="value" />
                                </div>
                            </div>
                            <div className="search-criteria-pb-col">
                                <span>{t("ORDER_PROCESSOR")}</span>
                                <div className="search-criteria-pb2-search">
                                    {/* <SearchSelection
                                        placeholder="Select Name"
                                        dataArr={SomeOrder}
                                        selectedObj={someOrder}
                                        onSelect={setSomeOrder}
                                        label="label"
                                        value="value"
                                        /> */}
                                    <SearchSelection placeholder={t("SELECT_NAME")} dataArr={ordProcesserData} selectedObj={selectedOrdProc} onSelect={setSelectedOrdProc} label="name" value="orderProcessorId" />
                                </div>
                            </div>
                            <div className="search-criteria-pb-col">
                                <span>{t("STATUS")}</span>
                                <div className="search-criteria-pb3-search">
                                    <SearchSelection placeholder={t("SELECT_STATUS")} dataArr={Status} selectedObj={status} onSelect={setStatus} label="label" value="value" />
                                </div>
                            </div>
                        </div>
                        <div className="search-criteria-flex">
                            <div className="search-criteria-pb-col">
                                <span>{t("DATE_RANGE")}</span>
                                <div className="search-criteria-flex-row">
                                    <div className="search-criteria-pb4-search">
                                        {/* <Calendar date={dateFrom} setDate={setDateFrom} placeHolder={t("FROM_DATE")} /> */}
                                    </div>
                                    <div className="search-criteria-pb5-search">
                                        {/* <Calendar date={dateTo} setDate={setDateTo} placeHolder={t("TO_DATE")} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="search-criteria-pb-col">
                                <span>{t("STATE")}</span>
                                <div className="search-criteria-flex-row">
                                    <div className="search-criteria-pb3-search">
                                        <SearchSelection placeholder={t("SELECT_NAME")} dataArr={State} selectedObj={state} onSelect={setState} label="label" value="value" />
                                    </div>
                                    <div className="search-criteria-pb6-search">
                                        <input type="text" className="form-control form-control-flush flex-grow-1 inputcolor" name="search" placeholder={t("FROM")} />
                                    </div>
                                    <div className="search-criteria-pb5-search">
                                        <input type="text" className="form-control form-control-flush flex-grow-1 inputcolor" name="search" placeholder={t("TO")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search-criteria-flex ">
                            <div className="search-criteria-pb-col">
                                <span>{t("CUSTOMER")}</span>
                                <div className="search-criteria-pb7-search">
                                    <SearchSelection placeholder={t("SELECT_NAME")} dataArr={Customer} selectedObj={customer} onSelect={setCustomer} label="label" value="value" />
                                </div>
                            </div>
                            {/* <div className="search-criteria-pb-col">
                                <span>Manufacturer</span>
                                <div className="search-criteria-pb8-search">
                                    <SearchSelection
                                    placeholder="Select Manufacturer"
                                    dataArr={Manufacturer}
                                    selectedObj={manufacturer}
                                    onSelect={setManufacturer}
                                    />
                                </div>
                                </div> */}
                            <div className="search-criteria-pb-col">
                                <span>{t("FILTERS")}</span>
                                <div className="search-criteria-pb7-search__">
                                    <div className="search-criteria-pb-col">
                                        <span className="search-criteria-pb-mr">{t("TYPE")}</span>
                                        <div className="search-criteria-pb12-search">
                                            <SearchSelection placeholder={t("ALL")} dataArr={Type} selectedObj={type} onSelect={setType} label="label" value="value" />
                                        </div>
                                    </div>
                                    <div className="search-criteria-pb-col">
                                        <span className="search-criteria-pb-mr">{t("SHIPMENT")}</span>
                                        <div className="search-criteria-pb13-search">
                                            <SearchSelection placeholder={t("ALL")} dataArr={Type} selectedObj={shipment} onSelect={setShipment} label="label" value="value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PopupPartNo onSelect={onSelect} selectedArr={selArr} onApply={onApply} checkedArr={checkedArr} />
                    </>
                )}
                {isStock === true && (
                    <div>
                        <div className="search-criteria-pb-col">
                            <span>{t("ORDER_PROCESSOR")} *</span>
                            <div className="search-criteria-pb7-search">
                                <SearchSelection
                                    placeholder="Select Order Processor"
                                    dataArr={ordProcesserData}
                                    selectedObj={selectedOrdProc}
                                    onSelect={setSelectedOrdProc}
                                    label="name"
                                    value="orderProcessorId"
                                    isLoading={loadingProc}
                                />
                            </div>
                        </div>
                        {/* <div className="search-criteria-flex"> */}
                        <div className="search-criteria-pb-col">
                            <span>{t("STOCK_LOCATION_GROUP")}</span>
                            <div className="search-criteria-pb7-search">
                                <SearchSelection
                                    placeholder={t("SELECT_STOCK_LOCATION_GROUP")}
                                    dataArr={stockLocationGroupData}
                                    selectedObj={selectedStockLocGroup}
                                    onSelect={handleStocklocationGroup}
                                    label="stockLocationGroupName"
                                    value="stockLocationGroupId"
                                    isLoading={loadingStockGroup}
                                />
                            </div>
                        </div>
                        <div className="search-criteria-pb-col">
                            <span>{t("STOCK_LOCATION")} *</span>
                            <div className="search-criteria-pb8-search">
                                <SearchSelection
                                    placeholder={t("SELECT_STOCK_LOCATION")}
                                    // dataArr={tempArr}
                                    dataArr={stockLocationData}
                                    selectedObj={selectedStockLocation}
                                    onSelect={setSelectedStockLocation}
                                    label="stockLocationName"
                                    value="stockLocationId"
                                    isLoading={loadingStockLocation}
                                />
                            </div>
                        </div>
                    </div>
                    // </div>
                )}
            </div>
            <div className="search-criteria-footer">
                {/*--- hide/bypass for now, as we discussed ---*/}
                {/* <div className="search-criteria-footer-data">
                    <span className="search-criteria-footer-title">Total Records</span>
                    <span className="search-criteria-footer__title">----</span>
                    <span className="search-criteria-footer-title">Search Time</span>
                    <span className="search-criteria-footer__title">----</span>
                </div> */}
                <div className="search-criteria-footer-btn">
                    <span onClick={handleReset} style={{ cursor: "pointer", pointerEvents: !isStock ? "none" : "auto" }}>{t("RESET")}</span>
                    <button onClick={handleSearchButton} disabled={!isStock}>
                        {loading ? <div className="white-btn-loader" /> : t("APPLY_SEARCH")}
                    </button>
                </div>
            </div>
        </section>
    );
}

export { SearchCriteria };
// export default SearchCriteria;
