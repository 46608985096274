import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials/layout";
import { AuthProviderFunc, getAccount } from "../../../functions";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context";
import { useContext } from "react";

const AsideToolbar = () => {
  const { state: { appConfig } } = useContext(AppContext);
  const authProvider = AuthProviderFunc({ appConfig });

  let _User = getAccount(authProvider);

  return (
    <>
      {/*begin::User*/}
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
        {/*begin::Symbol*/}
        <div className="symbol symbol-50px">
          <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          {/*begin::Section*/}
          <div className="d-flex">
            {/*begin::Info*/}
            <div className="flex-grow-1 me-2">
              {/*begin::Username*/}
              <div className="d-flex align-items-center ">
                <Link
                  to="#"
                  className="text-white text-hover-primary fs-6 fw-bold"
                >
                  <span>{_User?.name || "--"}</span>
                </Link>
                {/*begin::User menu*/}
                <div className="me-n2">
                  {/*begin::Action*/}
                  <Link
                    to="#"
                    className="btn btn-icon btn-sm btn-active-color-primary mt-n2 "
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-start"
                    data-kt-menu-overflow="false"
                  >
                    <KTSVG
                      path="/media/icons/duotune/coding/cod001.svg"
                      className="svg-icon-muted svg-icon-11"
                    />
                  </Link>

                  <HeaderUserMenu />
                  {/*end::Action*/}
                </div>
              </div>
              {/*end::User menu*/}
              {/*end::Username*/}

              {/*begin::Description*/}
              <span className="text-gray-600 fw-bold d-block fs-8 mb-1">
                Segen User
              </span>
              {/*end::Description*/}

              {/*begin::Label*/}
              <div className="d-flex align-items-center text-gray-600 fs-9">
                <span className="bullet bullet-dot bg-text-gray-600 me-1"></span>
                {_User?.userName || "--"}
              </div>
              {/*end::Label*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      <div className="aside-search"></div>
    </>
  );
};

export { AsideToolbar };
