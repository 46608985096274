import { FC, createContext, useContext } from 'react';
import { WithChildren } from '../helpers';
import { defaultCurrency } from '../utils';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

type Props = {
  // selectedLang: 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh',
  selectedLang: "en-GB" | "de-DE" | "es-ES" | "fr-FR" | "ja-JP" | "zh-CN";
  currencyCode: "GBP" | "EUR" | "USD" | "JPY" | "CNY" | string;
};
const language = window.navigator.language;
// const userLang = language.split('-')[0];
// const currencyCod = Intl.NumberFormat(language, {}).resolvedOptions().currency;


let supportedLangs = ['en-GB', 'de-DE', 'es-ES', 'fr-FR', 'ja-JP', 'zh-CN'];
let defLang: Props["selectedLang"] = 'en-GB';

// let selLang = supportedLangs.find((lang) => lang === language) ? language as Props["selectedLang"] : defLang;
let selLang = supportedLangs.includes(language) ? language as Props["selectedLang"] : defLang;

const initialState: Props = { selectedLang: selLang, currencyCode: defaultCurrency };

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try { return JSON.parse(ls) as Props }
    catch (er) { console.error(er) }
  };
  return initialState;
};

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
};

const I18nContext = createContext<Props>(initialState);

const useLang = () => useContext(I18nContext).selectedLang;

const MetronicI18nProvider: FC<WithChildren> = ({ children }) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
};

export { MetronicI18nProvider, useLang };