export const OrdersData = [
  {
    OrderPriority: 1,
    TotalOrder: 5,
    Category: [
      {
        Title: "All In Stock",
        Issued: 59,
        Valid: 364,
        BGIMg: "/images/AllInStock-BG-img.svg",
        BackgroundColor: "#f5f0ff",
        presenteg: "14",
        Color: "purpale",
        Backcolor: "back-purpale",
        Textcolor: "text-purpale",
      },
      {
        Title: "Status",
        Issued: 124,
        Valid: 299,
        BGIMg: "/images/Status-BG-img.svg",
        BackgroundColor: "#fff6e5",
        presenteg: "29",
        Color: "orange",
        Backcolor: "back-orange",
        Textcolor: "text-orange",
      },
      {
        Title: "Dispatch Date",
        Issued: 125,
        Valid: 198,
        BGIMg: "/images/DispatchDate-BG-img.svg",
        BackgroundColor: "#e2f9f8",
        presenteg: "53",
        Color: "green",
        Backcolor: "back-green",
        Textcolor: "text-green",
      },
      {
        Title: "Allocations",
        Issued: 47,
        Valid: 369,
        BGIMg: "/images/Allocations-BG-img.svg",
        BackgroundColor: "#ffeff1",
        presenteg: "11",
        Color: "red",
        Backcolor: "back-red",
        Textcolor: "text-red",
      },
      {
        Title: "Total",
        Issued: 455,
        Valid: 1230,
        BGIMg: "/images/Total-BG-img.svg",
        BackgroundColor: "#eff7ff",
        presenteg: "28",
        Color: "blue",
        Backcolor: "back-blue",
        Textcolor: "text-blue",
      },
    ],
    OrderDetails: [
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "In Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
    ],
  },
  {
    OrderPriority: 2,
    TotalOrder: 123,
    Category: [
      {
        Title: "All In Stock",
        Issued: 59,
        Valid: 364,
        BGIMg: "/images/AllInStock-BG-img.svg",
        BackgroundColor: "#f5f0ff",
        presenteg: "14",
        Color: "purpale",
        Backcolor: "back-purpale",
        Textcolor: "text-purpale",
      },
      {
        Title: "Status",
        Issued: 124,
        Valid: 299,
        BGIMg: "/images/Status-BG-img.svg",
        BackgroundColor: "#fff6e5",
        presenteg: "29",
        Color: "orange",
        Backcolor: "back-orange",
        Textcolor: "text-orange",
      },
      {
        Title: "Dispatch Date",
        Issued: 125,
        Valid: 198,
        BGIMg: "/images/DispatchDate-BG-img.svg",
        BackgroundColor: "#e2f9f8",
        presenteg: "53",
        Color: "green",
        Backcolor: "back-green",
        Textcolor: "text-green",
      },
      {
        Title: "Allocations",
        Issued: 47,
        Valid: 369,
        BGIMg: "/images/Allocations-BG-img.svg",
        BackgroundColor: "#ffeff1",
        presenteg: "11",
        Color: "red",
        Backcolor: "back-red",
        Textcolor: "text-red",
      },
      {
        Title: "Total",
        Issued: 455,
        Valid: 1230,
        BGIMg: "/images/Total-BG-img.svg",
        BackgroundColor: "#eff7ff",
        presenteg: "28",
        Color: "blue",
        Backcolor: "back-blue",
        Textcolor: "text-blue",
      },
    ],
    OrderDetails: [
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "In Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
    ],
  },
  {
    OrderPriority: 3,
    TotalOrder: 45,
    Category: [
      {
        Title: "All In Stock",
        Issued: 59,
        Valid: 364,
        BGIMg: "/images/AllInStock-BG-img.svg",
        BackgroundColor: "#f5f0ff",
        presenteg: "14",
        Color: "purpale",
        Backcolor: "back-purpale",
        Textcolor: "text-purpale",
      },
      {
        Title: "Status",
        Issued: 124,
        Valid: 299,
        BGIMg: "/images/Status-BG-img.svg",
        BackgroundColor: "#fff6e5",
        presenteg: "29",
        Color: "orange",
        Backcolor: "back-orange",
        Textcolor: "text-orange",
      },
      {
        Title: "Dispatch Date",
        Issued: 125,
        Valid: 198,
        BGIMg: "/images/DispatchDate-BG-img.svg",
        BackgroundColor: "#e2f9f8",
        presenteg: "53",
        Color: "green",
        Backcolor: "back-green",
        Textcolor: "text-green",
      },
      {
        Title: "Allocations",
        Issued: 47,
        Valid: 369,
        BGIMg: "/images/Allocations-BG-img.svg",
        BackgroundColor: "#ffeff1",
        presenteg: "11",
        Color: "red",
        Backcolor: "back-red",
        Textcolor: "text-red",
      },
      {
        Title: "Total",
        Issued: 455,
        Valid: 1230,
        BGIMg: "/images/Total-BG-img.svg",
        BackgroundColor: "#eff7ff",
        presenteg: "28",
        Color: "blue",
        Backcolor: "back-blue",
        Textcolor: "text-blue",
      },
    ],
    OrderDetails: [
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "In Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
    ],
  },
  {
    OrderPriority: 4,
    TotalOrder: 67,
    Category: [
      {
        Title: "All In Stock",
        Issued: 59,
        Valid: 364,
        BGIMg: "/images/AllInStock-BG-img.svg",
        BackgroundColor: "#f5f0ff",
        presenteg: "14",
        Color: "purpale",
        Backcolor: "back-purpale",
        Textcolor: "text-purpale",
      },
      {
        Title: "Status",
        Issued: 124,
        Valid: 299,
        BGIMg: "/images/Status-BG-img.svg",
        BackgroundColor: "#fff6e5",
        presenteg: "29",
        Color: "orange",
        Backcolor: "back-orange",
        Textcolor: "text-orange",
      },
      {
        Title: "Dispatch Date",
        Issued: 125,
        Valid: 198,
        BGIMg: "/images/DispatchDate-BG-img.svg",
        BackgroundColor: "#e2f9f8",
        presenteg: "53",
        Color: "green",
        Backcolor: "back-green",
        Textcolor: "text-green",
      },
      {
        Title: "Allocations",
        Issued: 47,
        Valid: 369,
        BGIMg: "/images/Allocations-BG-img.svg",
        BackgroundColor: "#ffeff1",
        presenteg: "11",
        Color: "red",
        Backcolor: "back-red",
        Textcolor: "text-red",
      },
      {
        Title: "Total",
        Issued: 455,
        Valid: 1230,
        BGIMg: "/images/Total-BG-img.svg",
        BackgroundColor: "#eff7ff",
        presenteg: "28",
        Color: "blue",
        Backcolor: "back-blue",
        Textcolor: "text-blue",
      },
    ],
    OrderDetails: [
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "In Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
      {
        TasCode: "TILE001",
        OrderNo: "TiPV03623",
        OrderDate: "20/06/2021",
        Customer: "Tile Energy Ltd.",
        OrderDetail: "Return of damaged panel",
        Value: 99,
        Margin: "9%",
        Status: "New",
        StatusA: "Awaiting Stock",
        Shipping: "13/04/2022",
        Stock: 10,
        StockI: "Out Stock",
        Info: "Order due to be shupped on 13/04/2022 but not revied since 25/03/2022",
        ViewDetails: [
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "24 x ESD-1008063, Arrived: 17/06/2022",
          "1 x GROWATT-3600-TL-XE-DC, Arrived:16/06/2022",
          "10 x JAM54s-30-410-MR-BF, Arrived: 16/06/2022",
        ],
      },
    ],
  },
];

export const MoveOrder = [
  {
    MoveOrder: "HoPV08889",
    OrderDetail: [
      {
        Code: "HoPV08889",
        PartNo: "CS3W-425P-EV02-F30",
        Created: "24/06/2022 13:19:41 Not live in new brand",
        Comment: "--",
      },
      {
        Code: "HoPV08889",
        PartNo: "SLT-120001-035",
        Created: "24/06/2022 13:19:41 Not live in new brand",
        Comment: "--",
      },
      {
        Code: "HoPV08889",
        PartNo: "SLT-120001-047",
        Created: "24/06/2022 13:19:41 Not live in new brand",
        Comment: "--",
      },
    ],
  },
];

export const PartNoData = [
  {
    PartNo: "ALPH-BAT-2.9-EXT2",
    Manufacturer: "Alpha ESS",
    Category: "47",
    Select: "--",
  },
  {
    PartNo: "ALPH-M48112-S",
    Manufacturer: "Alpha ESS",
    Category: "262",
    Select: "--",
  },
  {
    PartNo: "ALPH-SMILE-B3-IP651",
    Manufacturer: "Alpha ESS",
    Category: "45",
    Select: "--",
  },
  {
    PartNo: "AT-HPS100-CT1",
    Manufacturer: "ATESS",
    Category: "340",
    Select: "--",
  },
  {
    PartNo: "AT-HPS30-CT",
    Manufacturer: "ATES",
    Category: "100",
    Select: "--",
  },
  {
    PartNo: "ALPH-BAT-2.9-EXT",
    Manufacturer: "Alpha ESS",
    Category: "47",
    Select: "--",
  },
  {
    PartNo: "ALPH-M48112-S1",
    Manufacturer: "Alpha ESS",
    Category: "262",
    Select: "--",
  },
  {
    PartNo: "ALPH-SMILE-B3-IP652",
    Manufacturer: "Alpha ESS",
    Category: "45",
    Select: "--",
  },
  {
    PartNo: "AT-HPS100-CT2",
    Manufacturer: "ATESS",
    Category: "340",
    Select: "--",
  },
  {
    PartNo: "AT-HPS30-CT1",
    Manufacturer: "ATES",
    Category: "100",
    Select: "--",
  },
  {
    PartNo: "ALPH-BAT-2.9-EXT1",
    Manufacturer: "Alpha ESS",
    Category: "47",
    Select: "--",
  },
  {
    PartNo: "ALPH-M48112-S2",
    Manufacturer: "Alpha ESS",
    Category: "262",
    Select: "--",
  },
  {
    PartNo: "ALPH-SMILE-B3-IP65",
    Manufacturer: "Alpha ESS",
    Category: "45",
    Select: "--",
  },
  {
    PartNo: "AT-HPS100-CT",
    Manufacturer: "ATESS",
    Category: "340",
    Select: "--",
  },
  {
    PartNo: "AT-HPS30-CT2",
    Manufacturer: "ATES",
    Category: "100",
    Select: "--",
  },
];

export const IssuesSummaryData = [
  {
    Title: "Issues Summary",
    TotalIssues: 455,
    TotalValid: 1230,
    presenteg: 28,
    IssuesMoreDetail: [
      {
        Img: "/images/AllInStock-icon.svg",
        SummaryTitle: "All In Stock",
        Issues: 59,
        Valid: 365,
        presenteg: 14,
        LightBGColor: "#EEE5FF",
        DarkBGcolor: "#8950FC",
      },
      {
        Img: "/images/Status-icon.svg",
        SummaryTitle: "Status",
        Issues: 124,
        Valid: 299,
        presenteg: 29,
        LightBGColor: "#FFF4DE",
        DarkBGcolor: "#FFA800",
      },
      {
        Img: "/images/DispatchDate-icon.svg",
        SummaryTitle: "Dispatch Date",
        Issues: 124,
        Valid: 299,
        presenteg: 53,
        LightBGColor: "#C9F7F5",
        DarkBGcolor: "#1BC5BD",
      },
      {
        Img: "/images/Allocations-icon.svg",
        SummaryTitle: "Allocations",
        Issues: 47,
        Valid: 369,
        presenteg: 11,
        LightBGColor: "#FFE2E5",
        DarkBGcolor: "#F64E60",
      },
    ],
  },
];

export const BrandData = [
  { value: "segen", label: "Segen" /* , isFixed: true, isDisabled: true  */ },
  { value: "aab", label: "AAB" },
  { value: "bisol", label: "̆Bisol" },
  { value: "givEnergy", label: "GivEnrgy" },
  { value: "Huawei", label: "HUAWEI" },
  { value: "googwe", label: "GoodWe" },
];

export const SomeOrder = [
  { value: "Mercury", label: "Mercury" },
  { value: "Venus", label: "Venus" },
  { value: "Earth", label: "Earth" },
  { value: "Mars", label: "Mars" },
  { value: "Jupiter", label: "Jupiter" },
  { value: "Neptune", label: "Neptune" },
];

export const Status = [
  { value: "Incomplete", label: "Incomplete" },
  { value: "Pending", label: "Pending" },
  { value: "Shipped", label: "Shipped" },
  { value: "Cancelled", label: "Cancelled" },
];

export const State = [
  { value: "England", label: "England" },
  { value: "Scotland", label: "Scotland" },
  { value: "Wales", label: "Wales" },
  { value: "Northern Ireland", label: "Northern Ireland" },
];
export const Customer = [
  { value: "Tile Energy Ltd.", label: "Tile Energy Ltd." },
  { value: "MKM Building Supplie", label: "MKM Building Supplie" },
  { value: "Ablaze Green Energy", label: "Ablaze Green Energy" },
  { value: "Elco Group Limited", label: "Elco Group Limited" },
];

export const Manufacturer = [
  { value: "Panels", label: "Panels" },
  { value: "Mounting", label: "Mounting" },
  { value: "Storage Systems", label: "Storage Systems" },
  { value: "Inverters", label: "Inverters" },
  { value: "EV Charging", label: "EV Charging" },
];

export const Priority = [
  { value: "First Ordered", label: "First Ordered" },
  { value: "Promise Date", label: "Promise Date" },
  { value: "Processing Time", label: "Processing Time" },
  { value: "Availability of Product", label: "Availability of Product" },
];

export const ListingFilter = [
  { value: "All In Stock", label: "All In Stock" },
  { value: "Status", label: "Status" },
  { value: "Dispatch Date", label: "Dispatch Date" },
  { value: "Allocations", label: "Allocations" },
];

export const Type = [
  { value: "All", label: "All" },
  { value: "Listed", label: "Listed" },
];

export const Location = [
  { value: "Aberdeen", label: "Aberdeen" },
  { value: "Bristol", label: "Bristol" },
  { value: "Chelmsford", label: "Chelmsford" },
  { value: "Preston", label: "Preston" },
  { value: "Truro", label: "Truro" },
  { value: "York", label: "York" },
];

export const bucket1 = [
  { order: 1, data: "" }
]
export const bucket2 = [
  { order: 2, data: "" }
]
export const bucket3 = [
  { order: 3, data: "" }
]
export const bucket4 = [
  { order: 4, data: "" }
]
export const bucket5 = [
  { order: 5, data: "" }
]
export const bucket6 = [
  { order: 6, data: "" }
]
export const bucket7 = [
  { order: 7, data: "" }
]
export const bucket8 = [
  { order: 8, data: "" }
]
export const bucket9 = [
  { order: 9, data: "" }
]
export const bucket10 = [
  { order: 10, data: "" }
]
export const bucket11 = [
  { order: 11, data: "" }
]
export const bucket12 = [
  { order: 12, data: "" }
]
export const bucket13 = [
  { order: 13, data: "" }
]
export const bucket14 = [
  { order: 14, data: "" }
]

const defaultBucketData = [
  { order: 1, data: [] },
  { order: 2, data: [] },
  { order: 3, data: [] },
  { order: 4, data: [] },
  { order: 5, data: [] },
  { order: 6, data: [] },
  { order: 7, data: [] },
  { order: 8, data: [] },
  { order: 9, data: [] },
  { order: 10, data: [] },
  { order: 11, data: [] },
  { order: 12, data: [] },
  { order: 13, data: [] },
  { order: 14, data: [] },
];

const defBucketArr = Object.freeze({
  data: [
    {
      order: 1,
      data: [],
      subtitle: "ORDER.PRIORITY_ONE.SUBTITLE",
      info: [{
        title: "ORDER.PRIORITY_ONE.INFO_ONE",
        data: [],
      },
      {
        title: "ORDER.PRIORITY_ONE.INFO_TWO",
        data: [],
      },
      {
        title: "ORDER.PRIORITY_ONE.INFO_THREE",
        data: [],
      }],
      shipping_alc_Des: false
    },
    {
      order: 2,
      data: [],
      subtitle: "ORDER.PRIORITY_TWO.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_TWO.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_TWO.INFO_ONE_A",
              data: [// prefix: i
                "ORDER.PRIORITY_TWO.INFO_ONE_A_I",
                "ORDER.PRIORITY_TWO.INFO_ONE_A_II",
                "ORDER.PRIORITY_TWO.INFO_ONE_A_III",
                "ORDER.PRIORITY_TWO.INFO_ONE_A_IV",
                "ORDER.PRIORITY_TWO.INFO_ONE_A_V",
              ]
            },
            {
              title: "ORDER.PRIORITY_TWO.INFO_ONE_B",
              data: []
            }
          ],
        },
        // prefix: 1.
        {
          title: "ORDER.PRIORITY_TWO.INFO_TWO",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_TWO.INFO_TWO_A",
              data: []// prefix: i
            },
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 3,
      data: [],
      subtitle: "ORDER.PRIORITY_THREE.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_THREE.INFO_ONE",
          data: [],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 4,
      data: [],
      subtitle: "ORDER.PRIORITY_FOURTH.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_FOURTH.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_FOURTH.INFO_ONE_A",
              data: []
            },
            {
              title: "ORDER.PRIORITY_FOURTH.INFO_ONE_B",
              data: []
            }
          ],
        }, // prefix: 1.
        {
          title: "ORDER.PRIORITY_FOURTH.INFO_TWO",
          data: [],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 5,
      data: [],
      subtitle: "ORDER.PRIORITY_FIVE.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_FIVE.INFO_ONE",
          data: [],
        }, // prefix: 1.
        {
          title: "ORDER.PRIORITY_FIVE.INFO_TWO",
          data: [],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 6,
      data: [],
      subtitle: "ORDER.PRIORITY_SIX.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_SIX.INFO_ONE",
          data: [],
        }, // prefix: 1.
        {
          title: "ORDER.PRIORITY_SIX.INFO_TWO",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_SIX.INFO_TWO_A",
              data: []// prefix: i
            }
          ],
        }, // prefix: 1.
        {
          title: "ORDER.PRIORITY_SIX.INFO_THREE",
          data: [],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 7,
      data: [],
      subtitle: "ORDER.PRIORITY_SEVEN.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_SEVEN.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_SEVEN.INFO_ONE_A",
              data: []// prefix: i
            }
          ],
        }, // prefix: 1.
        {
          title: "ORDER.PRIORITY_SEVEN.INFO_TWO",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_SEVEN.INFO_TWO_A",
              data: []// prefix: i
            }
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 8,
      data: [],
      subtitle: "ORDER.PRIORITY_EIGHT.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_EIGHT.INFO_ONE",
          data: [],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 9,
      data: [],
      subtitle: "ORDER.PRIORITY_NINE.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_NINE.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_NINE.INFO_ONE_A",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_NINE.INFO_ONE_B",
              data: []// prefix: i
            }
          ],
        }, // prefix: 1.
        {
          title: "ORDER.PRIORITY_NINE.INFO_TWO",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_NINE.INFO_TWO_A",
              data: []// prefix: i
            }
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 10,
      data: [],
      subtitle: "ORDER.PRIORITY_TEN.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_TEN.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_TEN.INFO_ONE_A",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_TEN.INFO_ONE_B",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_TEN.INFO_ONE_C",
              data: [
                "ORDER.PRIORITY_TEN.INFO_ONE_C_I",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_II",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_III",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_IV",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_V",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_VI",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_VII",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_VIII",
                "ORDER.PRIORITY_TEN.INFO_ONE_C_IX",
              ]// prefix: i
            },
            {
              title: "ORDER.PRIORITY_TEN.INFO_ONE_D",
              data: []// prefix: i
            },
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 11,
      data: [],
      subtitle: "ORDER.PRIORITY_ELEVEN.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_ELEVEN.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_ELEVEN.INFO_ONE_A",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_ELEVEN.INFO_ONE_B",
              data: [
                "ORDER.PRIORITY_ELEVEN.INFO_ONE_B_I",
                "ORDER.PRIORITY_ELEVEN.INFO_ONE_B_II",
                "ORDER.PRIORITY_ELEVEN.INFO_ONE_B_III",
              ]// prefix: i
            },
            {
              title: "ORDER.PRIORITY_ELEVEN.INFO_ONE_C",
              data: []// prefix: i
            },
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 12,
      data: [],
      subtitle: "ORDER.PRIORITY_TWELVE.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_TWELVE.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_TWELVE.INFO_ONE_A",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_TWELVE.INFO_ONE_B",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_TWELVE.INFO_ONE_C",
              data: []// prefix: i
            }
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 13,
      data: [],
      subtitle: "ORDER.PRIORITY_THIRTEEN.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_THIRTEEN.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_THIRTEEN.INFO_ONE_A",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_THIRTEEN.INFO_ONE_B",
              data: []// prefix: i
            },
            {
              title: "ORDER.PRIORITY_THIRTEEN.INFO_ONE_C",
              data: []// prefix: i
            }
          ],
        },
      ],
      shipping_alc_Des: false
    },
    {
      order: 14,
      data: [],
      subtitle: "ORDER.PRIORITY_FOURTEEN.SUBTITLE",
      info: [ // prefix: 1.
        {
          title: "ORDER.PRIORITY_FOURTEEN.INFO_ONE",
          data: [// prefix: a.
            {
              title: "ORDER.PRIORITY_FOURTEEN.INFO_ONE_A",
              data: []// prefix: i
            },
          ],
        },
      ],
      shipping_alc_Des: false
    },
  ]
});

export { defaultBucketData, defBucketArr }