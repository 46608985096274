import { useEffect } from 'react'
import { toast, useToasterStore } from 'react-hot-toast';

const useToasterHook = () => {
    const { toasts } = useToasterStore();

    const TOAST_LIMIT = 1

    useEffect(() => {
        toasts
            // Only consider visible toasts
            .filter((t) => t.visible)
            // Is toast index over limit?
            .filter((_, i) => i >= TOAST_LIMIT)
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    }, [toasts]);

}

export default useToasterHook;