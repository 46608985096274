import { Suspense, useContext } from "react";
import { AppContext } from "./context";
import { useFeatureFlag } from "./hooks";
import { PageLoader } from "./components";
import { AuthProviderFunc } from "./functions";
import { BrowserRouter } from "react-router-dom";
import AzureAD from "react-aad-msal";
import AppRoutes from "./routes";

const App = () => {
    useFeatureFlag();
    const { state: { appConfig } } = useContext(AppContext);

    if (appConfig.loading) return <PageLoader isLoading />;

    const authProvider = AuthProviderFunc({ appConfig });

    return (
        // <MsalProvider instance={msalInstance}>
        <AzureAD provider={authProvider} forceLogin={true}>
            <BrowserRouter>
                <Suspense fallback={<PageLoader isLoading />}>
                    <AppRoutes />
                </Suspense>
            </BrowserRouter>
        </AzureAD>
        // </MsalProvider>
    );
};

export default App;