import { Outlet } from "react-router-dom";
import { MasterInit } from "../layout/MasterInit";
import { LayoutProvider } from "../layout/core";
import { I18nProvider } from "../i18n";
import { PrivateRoutes } from "./PrivateRoutes";
import { Toaster } from "react-hot-toast";
import { useI18nMessages, useToasterHook } from "../hooks";
import { PageLoader } from "../components";

function AppRouting() {
  useToasterHook();
  const { loadingIntl, intlObj } = useI18nMessages();

  if (loadingIntl) return <PageLoader isLoading />;

  return (
    <I18nProvider intlObj={intlObj}>
      <LayoutProvider>
        <Outlet />
        <PrivateRoutes />
        <MasterInit />
        <Toaster position="top-right" />
      </LayoutProvider>
    </I18nProvider>
  );
};

export default AppRouting;
