import { Dispatch } from "react";
import { IAction, IAppState, UserType } from "../types";

enum ActionType {
    SET_USER = 'SET_USER',
    RESET = 'RESET',
    SET_LOADING = "SET_LOADING",
    SET_TOKEN = "SET_TOKEN",
    SET_LAST_UPDATED = "SET_LAST_UPDATED",
    SET_APPCONFIG = "SET_APPCONFIG",
    SET_INTL_OBJ = "SET_INTL_OBJ",
};

const set_User = (dispatch: Dispatch<IAction>, User: UserType) => dispatch({ type: ActionType.SET_USER, User });
const resetUser = (dispatch: Dispatch<IAction>) => dispatch({ type: ActionType.RESET });
const set_Loading = (dispatch: Dispatch<IAction>, isLoading: boolean) => dispatch({ type: ActionType.SET_LOADING, isLoading });
const set_Token = (dispatch: Dispatch<IAction>, token: string) => dispatch({ type: ActionType.SET_TOKEN, token });
const set_LastUpdated = (dispatch: Dispatch<IAction>, lastUpdated: Date | null | string) => dispatch({ type: ActionType.SET_LAST_UPDATED, lastUpdated });
const set_AppConfig = (dispatch: Dispatch<IAction>, aConf: any) => dispatch({ type: ActionType.SET_APPCONFIG, aConf });
const set_IntlObj = (dispatch: Dispatch<IAction>, intlObj: IAppState["intlObj"]) => dispatch({ type: ActionType.SET_INTL_OBJ, intlObj });

export { ActionType, set_User, resetUser, set_Loading, set_Token, set_LastUpdated, set_AppConfig, set_IntlObj };