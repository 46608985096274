// import '../styles/components/pageLoader.css';
/**
 * @param {boolean} isLoading - loading status ( true - show loader , false - hide loader)
 * @param {boolean} isSmall - if loader show small then pass true
 * @param {boolean} inPlace - css class to display loader in center or specific position
 * @returns - page loading element
*/
interface Props {
  isLoading: boolean;
  isSmall?: boolean;
  inPlace?: boolean;
}
const PageLoader = ({ isLoading, isSmall = false, inPlace = false }: Props) => {
  const classNames = isSmall ? "page-loader page-loader-small" : "page-loader";

  return isLoading ? (
    <div className={`${classNames} ${inPlace ? "page-loader-in-place" : ""}`}>
      <span className="visually-hidden">Loading</span>
    </div>
  ) : null;
};

export { PageLoader };
