import ReactDOM from "react-dom/client";

import "./styles/index.scss";
import { AppContextProvider } from "./context";
import App from "./App";
import { StrictMode } from "react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </StrictMode>
);