import { ConfigurationSetting, AppConfigurationClient } from "@azure/app-configuration";

type ConfigType = {
    obj: {
        key: string,
        label: string
    },
    client: AppConfigurationClient
};


async function getConfigurationSettingWithLabel({ obj, client }: ConfigType): Promise<ConfigurationSetting> {
    return await client.getConfigurationSetting(obj);
};

export { getConfigurationSettingWithLabel }