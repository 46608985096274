import { FC, ReactNode } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';

import enMessages from './messages/en-GB.json';
import deMessages from './messages/de-DE.json';
import esMessages from './messages/es-ES.json';
import frMessages from './messages/fr-FR.json';
import jaMessages from './messages/ja-JP.json';
import zhMessages from './messages/zh-CN.json';

const allMessages = {
  "en-GB": enMessages,
  "de-DE": deMessages,
  "es-ES": esMessages,
  "fr-FR": frMessages,
  "ja-JP": jaMessages,
  "zh-CN": zhMessages
};

type I18nProviderType = {
  children: ReactNode;
  intlObj: typeof allMessages | any;
};


const I18nProvider: FC<I18nProviderType> = ({ children, intlObj /* = allMessages */ }) => {
  const locale = useLang();
  const messages = intlObj[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };