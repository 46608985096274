import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../helpers";

type Props = {
  to?: string | null;
  title: string;
  icon?: string;
  activeIc?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: string) => void;
};

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  activeIc,
  fontIcon,
  hasBullet,
  isOpen,
  setIsOpen,
}) => {
  const { pathname } = useLocation();
  const isActive = to ? checkIsActive(pathname, to) : false;
  const ic = isOpen ? activeIc || icon || "" : icon || "";

  // var dataAttribute = element?.getAttribute("data-kt-menu-trigger");
  // console.log("dataAttribute__", dataAttribute);

  return (
    <div className="redus mb-1">
      <div
        className={clsx(
          "menu-item",
          { "here show": isActive },
          "menu-accordion",
          "ps-2", "pe-2"
        )}
        data-kt-menu-trigger="click"
        style={{
          cursor: "pointer",
          userSelect: "none",
          backgroundColor: isOpen ? "#383845" : "transparent",
          borderRadius: "0.5rem",
        }}
      >
        <span className="menu-link" onClick={() => setIsOpen(title)}>
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && (
            <span className="menu-icon">
              <KTSVG path={ic} className="svg-icon-2" />
            </span>
          )}
          {fontIcon && <i className={clsx("bi fs-3", fontIcon)}></i>}
          <span className="menu-title">{title}</span>
          <span className="menu-arrow"></span>
        </span>
        <div
          className={clsx("menu-sub menu-sub-accordion menu-active", {
            "menu-active-bg": isActive,
            "pb-2": children,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
