import { useContext, useEffect } from "react";
import { AppContext } from "../context";
import { allAppConfigKeys } from "../utils";
import { set_AppConfig } from "../context/action";
import { appConfigMapToObjFunc } from "../functions";
import { getConfigurationSettingWithLabel } from "../config";
import { AppConfigurationClient } from "@azure/app-configuration";
const client = new AppConfigurationClient(process.env.REACT_APP_APPCONFIG_ENDPOINT || "");

function useFeatureFlag() {
    const { state: { appConfig }, dispatch } = useContext(AppContext);

    async function getSettings() {
        if (appConfig.loading) {
            if (appConfig.SOPAPLURL) {
                set_AppConfig(dispatch, { ...appConfig, loading: false });
            }
            else {
                let aConfObj = new Map<string, string>();
                Promise.all(allAppConfigKeys.map(async (obj) => {
                    await getConfigurationSettingWithLabel({ obj, client }).then(({ value, key }) => {
                        value && aConfObj.set(key, value);
                    }).catch(e => console.error(e));
                })).finally(async () => {
                    await Promise.all(aConfObj).finally(() => {
                        set_AppConfig(dispatch, appConfigMapToObjFunc(aConfObj));
                    });
                });
            }
        } else console.log("App_Config: wwdw", JSON.stringify(appConfig, null, 2));
    };

    useEffect(() => {
        getSettings();
        //eslint-disable-next-line
    }, []);

    return { appConfig, client };
};

export default useFeatureFlag;