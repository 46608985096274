import { IAppState, UserType } from "../types";
import { IMsalAuthProviderConfig, LoginType, MsalAuthProvider } from "react-aad-msal";
import { currencyCodes, currencySymbol, defaultCurrency } from "../utils";

const getOptions = (labelKey: string, valueKey: string, dataArr: any[]) => {
    let options = dataArr?.map((i) => ({ value: i[valueKey], label: i[labelKey] }));
    return options;
};

const getToken = () => {
    let localUser = localStorage.getItem("@User");
    if (localUser === null) { return undefined };
    let locObj = JSON.parse(localUser);
    return locObj?.token;
};

const getLocalUser = () => {
    let localUser = localStorage.getItem("@User");
    if (localUser === null) { return { "email": "", "name": "", "token": "" } };
    return JSON.parse(localUser) as UserType;
};


const getAuthToken = async (provider: MsalAuthProvider) => (await provider.getIdToken()).idToken.rawIdToken;
const getAccount = (provider: MsalAuthProvider) => (provider.getAccount());
const getAccountInfo = (provider: MsalAuthProvider) => (provider.getAccountInfo());
const onLogout = (provider: MsalAuthProvider) => (provider.logout());
// refresh token
const getRefreshToken = async (provider: MsalAuthProvider) => {
    // return (await provider.getAccessToken({ scopes: ['user.read'], account: provider.getAccount(), forceRefresh: true })).accessToken
    // return (await provider.acquireTokenSilent({ scopes: ['user.read'], account: provider.getAccount(), forceRefresh: true })).idToken.rawIdToken;
    return (await provider.getIdToken({ scopes: ['user.read'], account: provider.getAccount(), forceRefresh: true })).idToken.rawIdToken
};
// check is token time is about to expire

/**
 * 
 * @param provider 
 * @param minutesBeforeExpiration: number of minutes before the token expires.
 * @returns 
 */
const isTokenAboutToExpire = (provider: MsalAuthProvider, minutesBeforeExpiration: number): boolean => {
    const tokenExpiration = provider.getAccount()?.idTokenClaims.exp || 0;
    const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds


    // Calculate the remaining time in seconds before the token expires
    const remainingTime = typeof tokenExpiration === 'number' ? tokenExpiration - currentTime : 0;
    // console.log("remainingTime:", (remainingTime / 60).toFixed(1), "minit");


    // Convert minutesBeforeExpiration to seconds
    const expirationThreshold = minutesBeforeExpiration * 60;

    return remainingTime <= expirationThreshold;
};



/**
 * 
 * @param {string} num - use for convert particular enter amount in decimal formate
 * @returns this function can convert pass number to decimal and display only 2 degits after point.
 */
const toDecimal = (num: number | string, code: string) => {
    let Num = typeof num === "string" ? parseFloat(num) : num;
    const codeUp = code?.toUpperCase() as keyof typeof currencyCodes;
    let { locale } = currencyCodes[codeUp] || currencyCodes[defaultCurrency];
    let nf = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return nf.format(Num);
};

/**
 * @param  {string} currencyCode 
 * @returns - currency symbol 
 */
function getCurrencySymbol(currencyCode: string = "") {
    if (typeof currencyCode !== 'string') {
        return undefined;
    };
    const code = currencyCode?.toUpperCase() as keyof typeof currencySymbol;

    if (!Object.prototype?.hasOwnProperty.call(currencySymbol, code)) {
        return undefined;
    };

    return currencySymbol[code]
};
type priceType = { currency?: string, amount: number };

/** 
 * @param {priceType} p - price object
 * @returns - price in string format
 */
const toPrice = (p: priceType) => {
    return p?.currency && p?.amount >= 0 ? `${getCurrencySymbol(p?.currency)} ${toDecimal(p?.amount, p?.currency)}` : "--";
};

/** 
 * @param {priceType} p - price object
 * @param {string} lng - language code
 * @returns - price in string format with currency symbol and decimal
 */
const toIntlPrice = (p: priceType, lng?: string) => {
    if (/* p?.currency &&  */p?.amount !== undefined && p?.amount !== null) {
        let num = typeof p?.amount === "string" ? p?.amount === "" ? 0 : parseFloat(p?.amount) : p?.amount;

        const codeUp = typeof p?.currency === "string" ? p?.currency?.toUpperCase() as keyof typeof currencyCodes : defaultCurrency;
        let { locale } = currencyCodes[codeUp] || currencyCodes[defaultCurrency];
        let nf = new Intl.NumberFormat(lng || locale, {
            style: "currency", currency: codeUp, currencyDisplay: "narrowSymbol"
        });
        return nf.format(num);
    } else {
        return "--";
    }
};

function getEnvironmentVariable(name: string): string {
    const value = process.env[name.toUpperCase()] || process.env[name.toLowerCase()];
    if (!value) {
        throw new Error(`Environment variable ${name} is not defined.`);
    }
    return value;
}

/**
 * typeguard - for timewindow client filter
 */
function isTimeWindowClientFilter(clientFilter: any): clientFilter is { parameters: { Start: string; End: string } } {
    return (
        clientFilter.name === "Microsoft.TimeWindow" &&
        clientFilter.parameters &&
        clientFilter.parameters["Start"] &&
        clientFilter.parameters["End"] &&
        typeof clientFilter.parameters["Start"] === "string" &&
        typeof clientFilter.parameters["End"] === "string"
    );
};

function AuthProviderFunc({ appConfig: {
    'MSAL_AZUREAD_CLIENT_ID': clientId,
    'MSAL_AZURE_SIGNIN_AUTHORITY': authority,
    'LOGIN_LOGOUT_REDIRECT_URL': redirectUri
} }: { appConfig: IAppState["appConfig"] }) {
    const config = {
        auth: { clientId, authority, redirectUri },
        cache: {
            CacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    };

    // Authentication Parameters
    const authenticationParameters = { scopes: ['user.read'] }

    // Options
    const options: IMsalAuthProviderConfig = {
        loginType: LoginType.Redirect,
        tokenRefreshUri: window.location.origin + '/auth.html'
    };

    return new MsalAuthProvider(config, authenticationParameters, options);
};

function appConfigMapToObjFunc(aConfObj: Map<string, string>) {
    let aConfObjObj = Object.fromEntries(aConfObj);
    return {
        "LOGIN_LOGOUT_REDIRECT_URL": aConfObjObj?.LOGIN_LOGOUT_REDIRECT_URL,
        "MSAL_AZURE_SIGNIN_AUTHORITY": aConfObjObj?.MSAL_AZURE_SIGNIN_AUTHORITY,
        "MSAL_AZUREAD_CLIENT_ID": aConfObjObj?.MSAL_AZUREAD_CLIENT_ID,
        "SOPAPLURL": aConfObjObj?.SOPAPI_URL,
        loading: false
    };
};

export {
    getOptions, getToken, getLocalUser, getAuthToken, getAccount, getAccountInfo, onLogout,
    getRefreshToken, toIntlPrice, toPrice, toDecimal, getCurrencySymbol, getEnvironmentVariable,
    isTimeWindowClientFilter, AuthProviderFunc, appConfigMapToObjFunc, isTokenAboutToExpire
};