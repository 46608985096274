/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext } from "react";
// import {useAuth} from '../../../../app/modules/auth'
// import {Languages} from './Languages'
import { AuthProviderFunc, onLogout } from "../../../functions";
import { AppContext, resetUser } from "../../../context";

const HeaderUserMenu: FC = () => {
  // const {currentUser, logout} = useAuth()
  const { state: { appConfig }, dispatch, } = useContext(AppContext);
  const authProvider = AuthProviderFunc({ appConfig });


  const handleLogout = () => {
    localStorage.removeItem("@User");
    resetUser(dispatch);
    onLogout(authProvider);
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
      data-popper-placement="bottom-start"
    >
      <div className="px-3">
        <div className="text-danger p-5 d-flex align-items-center justify-content-between cursor-pointer" onClick={handleLogout}>
          <span>Logout</span>
          <i className="fa fa-sign-out text-danger"></i>
        </div>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
