import { useState, useEffect, ChangeEvent } from "react";
import { PartNoData } from "../../fake_data";
import { useDebounce } from "../../hooks";


interface Props {
  onSelect: (item: any, isRemove?: boolean) => void;
  selectedArr: any[];
  onApply: () => void;
  checkedArr: any[];
}

export default function PopupPartNo({
  onSelect,
  selectedArr,
  onApply,
  checkedArr,
}: Props) {
  const [filteredList, setFilteredList] = useState(PartNoData);

  const [open, setOpen] = useState(false);
  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchTerm, 500);

  function onChangeTxt(e: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(e.target.value);
  }

  useEffect(() => {
    const filteredList = PartNoData.filter((i) => {
      return (
        i.PartNo.toLowerCase().includes(debouncedValue.toLowerCase()) ||
        i.Manufacturer.toLowerCase().includes(debouncedValue.toLowerCase()) ||
        i.Category.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    });
    setFilteredList(filteredList);
  }, [debouncedValue]);

  return (
    <div>
      <div className="search-criteria-flex">
        <div className="search-criteria-pb2">
          <span /* onClick={handleOpen} */>Part No.</span>
          <button
            className="search-criteria-pb9-search"
            // disabled={selectedArr?.length < 0}
            onClick={handleOpen}
          >
            <div className="input_selection_part">
              <span className="input_selection_text">
                {"Search by Manufacturer, Category, Subcategory, Part No."}
              </span>
            </div>
          </button>
          <section
            className="part_no_select"
            // disabled={selectedArr?.length < 0}
          >
            {selectedArr?.length > 0 &&
              selectedArr.map((item, index) => {
                return (
                  <div key={index} className="input_selection">
                    <span className="input_selection_text">{item.PartNo}</span>
                    <img
                      onClick={() => onSelect(item, true)}
                      src="/images/Close_icon.svg"
                      alt="Close"
                      className="input_selection_img"
                    />
                  </div>
                );
              })}
          </section>
        </div>
      </div>
      {open && (
        <div
          className="modal fade show d-block"
          id="kt_modal_add_user"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-650px">
            <div className="modal-content">
              <section className="move-order">
                <div className="move-order-flex">
                  <div>
                    <span className="move-order-flex-name">Search</span>
                  </div>
                  <img
                    src="/images/Exit-icon.svg"
                    alt="icon"
                    onClick={handleClose}
                  />
                </div>
                {/* ================================================================================= */}
                <div className="move-order-flex-col">
                  <div className="d-flex align-items-center position-relative my-1">
                    <img
                      src="/images/Search_icon.svg"
                      alt="search"
                      className=" position-absolute ms-4"
                    />
                    <input
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control  ps-14"
                      placeholder="Search Manufacturer, Category, Subcategory, Part No."
                      value={searchTerm}
                      onChange={onChangeTxt}
                    />
                  </div>
                </div>
                {/* ================================================================================= */}
                <div className="table-responsive">
                  <section
                    className="table-info-popup"
                    style={{ overflowY: "scroll", height: "500px" }}
                  >
                    <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                      <thead className="table-title">
                        <tr className="fw-bold text-muted">
                          <th className="min-w-70px pb-4 ps-7">Part No.</th>
                          <th className="min-w-80px pb-4 ">Manufacturer</th>
                          <th className="min-w-120px pb-4">Category</th>
                          <th className="min-w-60px pb-4 pe-7">Select</th>
                        </tr>
                      </thead>
                      <tbody className="table-data">
                        {filteredList.map((itemOD, index) => {
                          return (
                            <tr className="popup-border" key={index}>
                              <td>
                                <div className="order-text1 fs-6  mt-3 ps-7">
                                  {itemOD.PartNo}
                                </div>
                              </td>
                              <td>
                                <div className="order-text1 d-block mt-3 fs-6">
                                  {itemOD.Manufacturer}
                                </div>
                              </td>
                              <td>
                                <div className="order-text1 d-block mt-3 fs-6">
                                  {itemOD.Category}
                                </div>
                              </td>
                              <td>
                                <div className="fw-bold order-text2 d-block mt-3 fs-6">
                                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      data-kt-check-target="#kt_table_users .form-check-input"
                                      checked={
                                        checkedArr.find(
                                          (i) => i.PartNo === itemOD.PartNo
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={() => onSelect(itemOD)}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </section>
                </div>
                {/* ================================================================================= */}
                <div className="move-order-data6 d-flex flex-row justify-content-sm-end m-6">
                  <div className="search-criteria-footer-btn">
                    <span onClick={handleClose} className="c-p">
                      Cancel
                    </span>
                    <button
                      onClick={() => {
                        onApply();
                        handleClose();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
